<template>
  <div v-if="visible">
    <el-dialog :visible="true" title="系统权限配置" width="800px" :before-close="closeTab" :close-on-click-modal="false">
      <el-row v-loading="loading">
        <el-form label-width="140px" size="small">
          <el-form-item :label="title">
            <el-select v-model="usId" clearable filterable @change="getSystemPower">
              <el-option v-for="item in systemList" :key="item.usId" :value="item.usId" :label="item.usName"></el-option>
              <template slot="empty">
                <p style="color:#909399;font-size:14px;text-align:center">请先给该账号分配系统</p>
              </template>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="filter-tree tree-container" style="padding: 10px 30px;height:500px;overflow-y:auto;">
          <!-- 全选按钮 -->
          <el-checkbox v-if="usId && treeData.length > 0" style="margin-left: 15px;"
            :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllClick">全选</el-checkbox>
          <el-tree
            ref="tree"
            :data="treeData"
            :props="defaultProps"
            :check-strictly="false"
            default-expand-all
            node-key="urId"
            @check="checkChange"
            :check-on-click-node="false"
            show-checkbox>
          </el-tree>
        </div>
      </el-row>
      <div slot="footer">
        <el-button size="small" @click="closeTab">关闭</el-button>
        <el-button type="primary" size="small" @click="powerConf" :loading="waiting">授权</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// role 文件夹下有该组件，先处理当前页面使用，后续合一 userRight老权限数据
import { updateUserRight, userRightNew } from '@/api/account'
import { editPower } from '@/api/post'
import { updateRolePermission } from '@/api/systemRole'
import { deepClone } from '@/utils/index'
export default {
  data() {
    return {
      // 自定义全选
      isIndeterminate: true,
      checkAll: false,
      allNodeData: [],
      title: '该用户拥有的系统',
      usId: null,
      treeData: [],
      defaultProps: {
        label: 'umTitle',
        children: 'childUserMenu'
      },
      selectedIds: [],
      loading: false, // 处理节点重新渲染
      waiting: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    systemList: {
      type: Array,
      default: function() {
        return []
      }
    },
    userId: {
      type: Number,
      default: null
    },
    roleId: {
      type: Number,
      default: null
    },
    upId: {
      type: Number,
      default: null
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.roleId) {
          this.title = '该角色拥有的系统'
        } else if (this.upId) {
          this.title = '该岗位拥有的系统'
        } else {
          this.title = '该用户拥有的系统'
        }
      }
    }
  },
  methods: {
    closeTab() {
      this.treeData = []
      this.usId = null
      this.$emit('update:visible', false)
    },
    getSystemPower(val) {
      this.selectedIds = []
      this.treeData = []
      this.allNodeData = []
      if (val) {
        this.loading = true
        userRightNew({
          userId: this.userId,
          usId: val,
          systemRoleId: this.roleId,
          positionId: this.upId
        }).then(res => {
          this.loading = false
          if (res.code === '000000' && res.data) {
            setTimeout(() => {
              this.handleData(res.data)
              this.treeData = deepClone(res.data)
              this.$nextTick(() => {
                this.$refs.tree.setCheckedKeys(this.selectedIds)
              })
              if (this.treeData && this.treeData.length > 0) {
                this.getNodeData(this.treeData, this.allNodeData)
                // 顶部全选按钮回显处理
                if (this.selectedIds && this.selectedIds.length === 0) {
                  this.checkAll = false
                  this.isIndeterminate = false
                } else if (this.selectedIds.length === this.allNodeData.length) {
                  this.checkAll = true
                  this.isIndeterminate = false
                } else {
                  this.checkAll = false
                  this.isIndeterminate = true
                }
              }
              this.reSet()
            }, 1000)
          }
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      }
    },
    // 手动修改树形的结构，最末端节点横向排列（数据量较大）
    reSet() {
      setTimeout(() => {
        const nodes = document.querySelectorAll('.filter-tree .el-tree-node .el-tree-node__children')
        nodes.forEach(dom => {
          if (dom.innerHTML === '') {
            dom.parentNode.style.float = 'left'
            dom.previousSibling.style.background = '#ffffff'
          }
        })
        const nodes_one_arr = document.querySelectorAll('.el-tree >.el-tree-node>.el-tree-node__children') // 处理只有一层的数据
        nodes_one_arr.forEach(dom => {
          if (dom.innerHTML === '') {
            dom.previousSibling.style.background = '#e0e3eb'
            dom.parentNode.style.float = 'none'
          }
        })
        // const nodes_on = nodes_one_arr.filter(dom => { return dom.innerHTML === '' })
        setTimeout(() => {
          this.loading = false
        }, 500)
      }, 100)
    },
    powerConf() {
      const checkedKeys = this.$refs.tree.getCheckedNodes().filter(item => item.urId) // 选中的节点的对象
      console.log(checkedKeys)
      // const halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys() // 选中节点的相关父节点ID，半选状态
      if (this.treeData.length > 0 && this.usId) {
        if (this.roleId) {
          this.updateRoleRight(checkedKeys)
        } else if (this.upId) {
          this.updatePostRight(checkedKeys)
        } else {
          this.updateUserRight(checkedKeys)
        }
      } else {
        this.$message.warning('请先配置权限！')
      }
    },
    updateUserRight(checkedKeys) {
      const params = {
        usId: this.usId,
        userId: this.userId,
        userRightIds: checkedKeys.map(item => item.urId)
      }
      this.waiting = true
      updateUserRight(params).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.closeTab()
        }
        this.waiting = false
      }).catch(() => {
        this.waiting = false
      })
    },
    updateRoleRight(checkedKeys) {
      const params = {
        roleId: this.roleId,
        urIds: checkedKeys.map(item => item.urId),
        usId: this.usId
      }
      this.waiting = true
      updateRolePermission(params).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.closeTab()
        }
        this.waiting = false
      }).catch(() => {
        this.waiting = false
      })
    },
    updatePostRight(checkedKeys) {
      const params = {
        ucId: process.env.VUE_APP_COMPANY_ID,
        usId: this.usId,
        upId: this.upId,
        urIds: checkedKeys.map(item => item.urId)
      }
      this.waiting = true
      editPower(params).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.closeTab()
        }
        this.waiting = false
      }).catch(() => {
        this.waiting = false
      })
    },
    getNodeData(list, arr) {
      list.forEach(element => {
        if (element.childUserMenu) {
          this.getNodeData(element.childUserMenu, arr)
        } else {
          arr.push(element)
        }
      })
    },
    handleCheckAllClick(val) {
      this.isIndeterminate = false
      this.$refs.tree.setCheckedNodes(val ? this.allNodeData : [])
    },
    checkChange(nodeItem, checked) {
      const checkedKeyList = checked.checkedKeys.filter(item => item !== undefined)
      this.checkAll = checkedKeyList.length === this.allNodeData.length
      this.isIndeterminate = this.allNodeData.length > checkedKeyList.length && checkedKeyList.length > 0
    },
    handleData(data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        if (item.childUserMenu && item.childUserMenu.length === 1 && item.umTitle === item.childUserMenu[0].umTitle) {
          item.userRightMenuVoList = item.childUserMenu[0].userRightMenuVoList
          item.childUserMenu = []
        }
        if (item.userRightMenuVoList && item.userRightMenuVoList.length > 0) {
          item.userRightMenuVoList.forEach(el => {
            if (el.have && el.have === 1) {
              this.selectedIds.push(el.urId)
            }
            el.umTitle = el.urName
          })
          item.childUserMenu = [...item.userRightMenuVoList]
          this.handleData(item.childUserMenu)
        } else if (item.childUserMenu && item.childUserMenu.length > 0) {
          this.handleData(item.childUserMenu)
        }
      }
    }
  }
}
</script>
<style lang="scss">
  $bg-color: #DCDFE6;
  @mixin node-is-expanded {
    .el-tree-node.is-expanded {
      & > {
        .el-tree-node__content {
          border-bottom: 0;
        }
      }
    }
  }

  @mixin node-content($bg-color, $n1, $n2) {
    .el-tree-node__content {
      height: 32px;
      background-color: $bg-color * $n1;
      border-bottom: 1px solid $bg-color * $n2;
    }
  }

  @mixin node-children {
    .el-tree-node__children {
      & > {
        .el-tree-node {
          float: left;
        }
      }
    }
  }

  .tree-container {
    .el-tree {
      & > {
        .el-tree-node {
          & > {
            @include node-content($bg-color, 1.02, 0.92);
            .el-tree-node__children {
              & > {
                .el-tree-node {
                  & > {
                    @include node-content($bg-color, 1.08, 1);
                    .el-tree-node__children {
                      & > {
                        .el-tree-node {
                          & > {
                            @include node-content($bg-color, 1.1, 1.1);
                            .el-tree-node__children {
                              & > {
                                .el-tree-node {
                                  & > {
                                    @include node-content($bg-color, 1.21, 1.21);
                                    // @include node-children;
                                  }
                                }
                                @include node-is-expanded;
                              }
                            }
                          }
                        }
                        @include node-is-expanded;
                      }
                    }
                  }
                }
                @include node-is-expanded;
              }
            }
          }
        }
        @include node-is-expanded;
      }
    }
    .el-loading-mask {
      background-color: rgba(255, 255, 255, 1)
    }
  }
</style>
