import request from '@/utils/request'

export function getSystemRolePage(reqParams) {
  return request({
    url: '/systemRole/get/page',
    method: 'POST',
    data: reqParams
  })
}
export function systemRoleAdd(reqParams) {
  return request({
    url: '/systemRole/add',
    method: 'POST',
    data: reqParams
  })
}
export function systemRoleUpdate(reqParams) {
  return request({
    url: '/systemRole/edit',
    method: 'POST',
    data: reqParams
  })
}
// 改变角色启用状态
export function systemRoleStatusUpdate(reqParams) {
  return request({
    url: '/systemRole/update/status',
    method: 'PUT',
    data: reqParams
  })
}
export function systemRoleDelete(reqParams) {
  return request({
    url: '/systemRole/delete/' + reqParams,
    method: 'DELETE',
    data: reqParams
  })
}
// 获取上级角色架构
export function getRoleSupInfo() {
  return request({
    url: '/systemRole/getRoleSupInfo',
    method: 'GET'
  })
}
// 给角色分配系统
export function updateRoleSystem(reqParams) {
  return request({
    url: '/userSystemRoleMap/edit',
    method: 'POST',
    data: reqParams
  })
}
// 获取角色下的权限
export function getRolePermission(reqParams) {
  return request({
    url: '/userRolePermissionsMap/getUserRolePermissions',
    method: 'GET',
    params: reqParams
  })
}
// 给角色分配权限
export function updateRolePermission(reqParams) {
  return request({
    url: '/userRolePermissionsMap/edit',
    method: 'POST',
    data: reqParams
  })
}
// 获取所有系统列表
export function getAllSystemList() {
  return request({
    url: '/userSystem/findAll',
    method: 'GET'
  })
}
