import request from '@/utils/request'

// 通过id获取公司 /company/{id}
export function getCompany(ucId) {
  return request({
    url: `/company/get/${ucId}`,
    method: 'get'

  })
}

// 查询所有公司
export function getCompanyList() {
  return request({
    url: '/company/get/all',
    method: 'get'

  })
}

// 获取职位列表,根据输入查询，得到分页数据，点击查询
// export function getPostList(searchForm) {
//     return request({
//       url: '/position/get/pageV1',
//       method: 'get',
//       params:searchForm
//     })
//   }
export function getPostList(searchForm) {
  return request({
    url: '/position/get/page',
    method: 'get',
    params: searchForm
  })
}

// 查询公司下所有 部门列表  点击添加------没有公司id？
export function getAllBumenList() {
  return request({
    url: '/department/udNames',
    method: 'get'

  })
}

// 查询所有职位等级  点击添加
export function getAllUpLevel() {
  return request({
    url: '/position/get/levels',
    method: 'get'

  })
}

// 添加提交事件  点击添加>提交
export function addPost(addForm) {
  return request({
    url: '/position/add',
    method: 'post',
    data: addForm
  })
}

// 查询部门下所有的等级
export function getUpNames(udId) {
  return request({
    url: `/position/${udId}/upNames`,
    method: 'get'
  })
}

// 查询部门id下所有职位名称
export function getIdAllUpNames(udId) {
  return request({
    url: `/position/${udId}/upNames`,
    method: 'get'
  })
}

// 通过id获取职位
export function editIdPostData(upId) {
  return request({
    url: `/position/get/${upId}`,
    method: 'get'
    // params: { id }
  })
}

// 通过公司id 部门Id获取直接上级，新接口
export function getIdPostParent(ucId, udId) {
  return request({
    url: '/position/get/upNames',
    method: 'get',
    params: { ucId: ucId, udId: udId }
  })
}

// 查询公司下所有部门/department/{ucId}/udNames
export function getCompanyUd(ucId) {
  return request({
    url: `/department/get/${ucId}/udNames`,
    method: 'get'

  })
}

// 提交编辑完成的职位
export function editPostSubmit(editForm) {
  return request({
    url: '/position/update',
    method: 'put',
    data: editForm
  })
}

// 导出职位Id的所有权限
export function exportPower(idData, token2) {
  return request({
    url: '/position/get/rights/csv',
    method: 'get',
    params: idData,
    headers: {
      token: token2
    },
    responseType: 'blob'
  })
}

// 通过Id删除职位
export function deletePost(upId) {
  return request({
    url: `/position/delete/${upId}`,
    method: 'delete'
  })
}

// 复制职位
export function copyPost(copyData) {
  return request({
    url: '/position/update/copy',
    method: 'put',
    data: copyData
  })
}

// 查询职位id下所有的权限
export function getPowerList(idData) {
  return request({
    url: '/position/get/rights',
    method: 'get',
    params: idData
  })
}

// 修改用户权限
export function editPower(params) {
  //  let ss = urIds.split(",")
  return request({
    url: '/position/update/rights',
    method: 'put',
    data: params
  })
}

// 岗位分配角色
export function editPostRole(params) {
  return request({
    url: '/position/editPositionRoles',
    method: 'put',
    data: params
  })
}

// /userSystemMap/get/userId/{id}
// 通过用户id查询拥有的系统（选择系统，渲染权限列表）----------更改 非这个userId接口，需要公司系统，
export function getUserSystemList(userId) {
  return request({
    url: `userSystemMap/get/userId/${userId}`,
    method: 'get'

  })
}

// /通过公司id查询拥有的系统 /userSystemCompanyMap/get/{id}
export function getSystemList(ucId) {
  return request({
    url: `/userSystemCompanyMap/get/${ucId}`,
    method: 'get'

  })
}

// /通过公司id查询拥有的系统 /userSystemCompanyMap/get/{id}
export function getPostSystemList(upId) {
  return request({
    url: `/userSystemPositionMap/getUserSystemByPosition`,
    method: 'get',
    params: { upId: upId }
  })
}
// 入职岗位系统
export function inductionSystemList() {
  return request({
    url: `/inductionSystem/get/list`,
    method: 'get'
  })
}
// 入职岗位列表
export function configureJobsPage(params) {
  return request({
    url: `/configureJobs/get/page`,
    method: 'get',
    params: params
  })
}
// 获取IT负责人列表->周祥宇
export function maintenancePeoples(params) {
  return request({
    url: `/configureJobs/get/maintenancePeoples`,
    method: 'get',
    params: params
  })
}
// /configureJobs/correlation/system关联入职系统
export function correlationSystem(params) {
  return request({
    url: `/configureJobs/correlation/system`,
    method: 'post',
    data: params
  })
}
// 編輯系統
export function updateSystem(params) {
  return request({
    url: `/inductionSystem/update`,
    method: 'put',
    data: params
  })
}
// tianjia系統
export function addSystem(params) {
  return request({
    url: `/inductionSystem/add`,
    method: 'post',
    data: params
  })
}
export function deleteSystem(id) {
  return request({
    url: `/inductionSystem/delete/` + id,
    method: 'delete'
  })
}
// 批量修改入职岗位配置状态
export function updateStatus(data) {
  return request({
    url: `/configureJobs/batch/ffective`,
    method: 'put',
    data: data
  })
}
// 单个修改入职岗位配置状态
export function updateConfigureJobs(data) {
  return request({
    url: `/configureJobs/update`,
    method: 'put',
    data: data
  })
}
// 是否有授权
export function getIsShowButtom(data) {
  return request({
    url: `/configureJobs/get/isShowButtom`,
    method: 'get',
    params: data
  })
}
// 获取登录用户负责部门下所有用户接口
export function getDepartmentUser(data) {
  return request({
    url: `/configureJobs/get/departmentUser`,
    method: 'get',
    params: data
  })
}
// 添加负责人分权表
export function separationPowers(data) {
  return request({
    url: `/separationPowers/add`,
    method: 'post',
    data: data
  })
}
// /separationPowers/get/page分页查询负责人分权表
export function separationPowersPage(data) {
  return request({
    url: `/separationPowers/get/page`,
    method: 'get',
    params: data
  })
}
// /separationPowers/get/page分页查询负责人分权表
export function deleteSeparationPowers(id) {
  return request({
    url: `/separationPowers/delete/` + id,
    method: 'delete'
  })
}
// 人事查询岗位
export function getTemporaryPage(params) {
  return request({
    url: `/configureJobs/get/temporaryPage`,
    method: 'get',
    params: params
  })
}

// 编辑岗位与系统关系表
export function userSystemPositionMapEdit(data) {
  return request({
    url: `/userSystemPositionMap/edit`,
    method: 'post',
    data: data
  })
}

// 更改部门状态
export function updateUpState(data) {
  return request({
    url: '/position/update/position-state',
    method: 'put',
    data: data
  })
}
// 复制岗位权限
export function copyPositionInfo(data) {
  return request({
    url: '/position/copyPositionInfo',
    method: 'put',
    data: data
  })
}